import { fallbackLocale } from '@/config/locales';

export interface UseTranslationsReturn {
  /**
   * Get the current locale among an array, with a fallback if the current locale is not in this array
   * @param locales - Array of wanted locales
   * @param fallback - Fallback locale when current locale is not in wanted locales array. Defaults to config fallback locale
   */
  pickLocaleWithFallback(locales: string[], fallback?: string): string;
}

/**
 * Composable to use a `lang` computed proxy on top of vue-router, which also sets vue-i18n `locale`
 * and triggers a fetch on translations API and sets new messages
 * @param fallbackLang - Lang to use in fallback
 */
export function useTranslations(): UseTranslationsReturn {
  const { locale } = useI18n();

  function pickLocaleWithFallback(
    locales: string[],
    fallback = fallbackLocale
  ): string {
    return locales.includes(locale.value) ? locale.value : fallback;
  }

  return { pickLocaleWithFallback };
}
